import React from 'react';

const ComingSoon = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-600 to-blue-500 flex flex-col items-center p-8">
      {/* Title */}
      <h1 className="text-4xl font-bold text-white mb-6">Coming Soon</h1>
        <br />
      {/* Paragraph */}
      <p className="text-lg text-white text-center max-w-3xl mb-12">
        We are working hard on bringing more data and functionality to you.
      </p>
      <p className="text-lg text-white text-center max-w-3xl mb-4">
        <strong>Coming in the near future:</strong>
      </p>
      <ul className="list-disc text-white text-lg max-w-3xl">
        <li className="mb-4">Filtering & Searching functionality on full investors database</li>
        <li className="mb-4">Google Authentication</li>
        <li className="mb-4">Improved filtering functionality on other 3 databases</li>
        <li className="mb-4">Founder's Dictionary</li>
        <li className="mb-4">Further enriched data across all databases</li>
        <li className="mb-4">Missing data submission & Feature request form</li>
        <li className="mb-4">Improved account management</li>
      </ul>
      <br />
      <p className="text-lg text-white text-center max-w-3xl mb-12">
        You can expect this update in February!
      </p>
    </div>
  );
};

export default ComingSoon;