import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-600 to-blue-500 flex flex-col items-center p-8">
      {/* Title */}
      <h1 className="text-4xl font-bold text-white mb-6">Terms and Conditions</h1>

      {/* Content Box */}
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl">
        <p className="text-gray-800 mb-4">
          <strong>Effective Date:</strong> 1st of January 2025
        </p>
        <p className="text-gray-800 mb-4">
          Welcome to MakerTerminal! By accessing or using our Service, you agree to the following Terms and Conditions.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Use of the Service</h2>
        <ul className="list-disc list-inside text-gray-800 mb-4">
          <li>The Service is provided "as is" for educational and analytical purposes.</li>
          <li>You agree not to misuse the Service, including introducing malicious software or disrupting its operation.</li>
        </ul>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. Intellectual Property</h2>
        <p className="text-gray-800 mb-4">
          All content is the intellectual property of MakerTerminal or its licensors. You may not reproduce or modify content without prior written consent.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Disclaimers</h2>
        <p className="text-gray-800 mb-4">
          The Service is provided "as is" without warranties. We do not guarantee the accuracy of data or analyses.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Governing Law</h2>
        <p className="text-gray-800 mb-4">
          These Terms are governed by the laws of the European Union. Disputes will be resolved exclusively in the European Union.
        </p>
        <p className="text-gray-800 mb-4">
          For full details, contact us at info@makerterminal.com
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
