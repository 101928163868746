import React from "react";
import { Link } from "react-router-dom";
import footerImage from "../images/mtlogocloseup3.png";

const Footer = () => {
  return (
    <footer className="w-full bg-purple-100 text-gray-800 py-6 flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center">
          <div className="ml-[10%] w-64 h-16 flex items-center justify-center">
            <img src={footerImage} alt="MakerTerminal Logo" />
          </div>
        </div>
        <div className="flex flex-wrap space-x-6 mt-2 sm:mt-0">
          <Link
            to="/privacy"
            className="text-m text-gray-700 hover:text-blue-600 hover:underline"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms"
            className="text-m text-gray-700 hover:text-blue-600 hover:underline"
          >
            Terms & Conditions
          </Link>
        </div>
        <div className="mr-[5%] text-center md:text-right mt-4 md:mt-0">
          &copy; 2025 MakerTerminal. All rights reserved.
        </div>
    </footer>
  );
};

export default Footer;
