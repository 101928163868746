import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { supabase } from '../supabaseClient';

Modal.setAppElement("#root");

const GrantsPage = () => {
  const [nameFilter, setNameFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [moneyFilter, setMoneyFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedGrant, setSelectedGrant] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchData = async (page = 1) => {
    const limit = 50;
    const offset = (page - 1) * limit;
  
    try {
      const { data, count, error } = await supabase
        .from('grants')
        .select('*', { count: 'exact' })
        .range(offset, offset + limit - 1);
  
      if (error) throw error;
  
      setFilteredData(data);
      setTotalPages(Math.ceil(count / limit));
    } catch (err) {
      console.error('Error fetching data:', err.message);
    }
  };
  

  const fetchFilteredData = async (page = 1) => {
    const limit = 50;
    const offset = (page - 1) * limit;
  
    try {
      let query = supabase
      .from('grants') // Replace with your table name
      .select('*', { count: 'exact' }) // Include the total count
      .range(offset, offset + limit - 1); // Pagination

      if (nameFilter) {
        query = query.ilike('name', `%${nameFilter}%`);
      } 
      if (typeFilter) {
        query = query.ilike('type', `%${typeFilter}%`);
      }
      if (moneyFilter) {
        query = query.ilike('money_to', `%${moneyFilter}%`);
      }
      const { data, count, error } = await query;
      if (error) throw error;
  
      setFilteredData(data);
      setTotalPages(Math.ceil(count / limit));
    } catch (err) {
      console.error('Error fetching filtered data:', err.message);
      setFilteredData([]);
    }
  };
  

  const handleApplyFilter = () => {
    setCurrentPage(1);
    fetchFilteredData(1);
  };

  const handleResetFilters = () => {
    setNameFilter("");
    setTypeFilter("");
    setMoneyFilter("");
    setCurrentPage(1);
    fetchData(1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleGrantClick = (grant) => {
    setSelectedGrant(grant);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedGrant(null);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Title */}
      <h1 className="text-4xl font-bold text-center mt-6 mb-4">Grants</h1>
      <p className="text-lg text-gray-700 text-center ml-36 mr-36 mb-4">
        Welcome to the grants database. Search and query through the data as you'd like!
      </p>

      <div className="flex w-full px-8">
        {/* Sidebar for Filters */}
        <div
          className="bg-gradient-to-b from-blue-500 to-purple-500 shadow-md rounded-lg p-6 sticky top-20 self-start"
          style={{
            minwidth: "350px", // Made sidebar wider
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <h2 className="text-lg font-bold text-white mb-4">Filters</h2>
          <div className="space-y-4">
            <input
              type="text"
              placeholder="Filter by Name"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              className="px-3 py-2 border rounded-lg text-sm w-full"
            />
            <select
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value)}
              className="px-3 py-2 border rounded-lg text-sm w-full"
            >
              <option value="">Select Type</option>
              <option value="Grant">Grant</option>
              <option value="Fellowship">Fellowship</option>
              <option value="Scholarship">Scholarship</option>
              <option value="Support">Support</option>
            </select>
            <select
              value={moneyFilter}
              onChange={(e) => setMoneyFilter(e.target.value)}
              className="px-3 py-2 border rounded-lg text-sm w-full"
            >
              <option value="">Select Money For...</option>
              <option value="Individual">Individuals</option>
              <option value="Compan">Companies</option>
              <option value="Non-Profit">Non-Profits</option>
              <option value="Student">Students</option>
            </select>
            <div>
              <p className="text-white">Click on a Grant Name to see more information</p>
            </div>
            <button
              onClick={handleApplyFilter}
              className="w-full py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg text-sm"
            >
              Apply Filter
            </button>
            <button
              onClick={handleResetFilters}
              className="w-full py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg text-sm"
            >
              Reset Filters
            </button>
          </div>
        </div>

        {/* Data Table */}
        
        <div className="flex-grow bg-gray-100 p-4">
          <table className="min-w-full bg-white shadow-md rounded-lg text-sm">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-left">Name</th>
                <th className="py-2 px-4 border-b text-left">Website</th>
                <th className="py-2 px-4 border-b text-left">Funding</th>
                <th className="py-2 px-4 border-b text-left">Type</th>
                <th className="py-2 px-4 border-b text-left">Money For...</th>
                <th className="py-2 px-4 border-b text-left">Institution</th>
                <th className="py-2 px-4 border-b text-left">Description</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((grant) => (
                <tr key={grant.id} className="h-12">
                  <td
                    className="py-2 px-4 border-b truncate max-w-[200px] cursor-pointer hover:underline"
                    onClick={() => handleGrantClick(grant)}
                  >
                    {grant.name}
                  </td>
                  <td className="py-2 px-4 border-b truncate max-w-[150px]">
                    <a
                      href={grant.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      {grant.website}
                    </a>
                  </td>
                  <td className="py-2 px-4 border-b truncate max-w-[160px] hover:whitespace-nowrap">
                    {grant.funding}
                  </td>
                  <td className="py-2 px-4 border-b truncate max-w-xs">
                    <div className="flex flex-wrap">
                      {grant.type
                        ? grant.type.split(",").map((tag, index) => (
                            <span
                              key={index}
                              className="inline-block bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded mb-1"
                            >
                              {tag.trim()}
                            </span>
                          ))
                        : ""}
                    </div>
                  </td>
                  <td className="py-2 px-4 border-b truncate max-w-[160px]">
                    <div className="flex flex-wrap">
                      {grant.money_to
                        ? grant.money_to.split(",").map((tag, index) => (
                            <span
                              key={index}
                              className="inline-block bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded mb-1"
                            >
                              {tag.trim()}
                            </span>
                          ))
                        : ""}
                    </div>
                  </td>
                  <td className="py-2 px-4 border-b truncate max-w-[160px] hover:whitespace-nowrap">
                    {grant.institution}
                  </td>
                  <td className="py-2 px-4 border-b truncate max-w-[160px] hover:whitespace-nowrap">
                    {grant.description}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-between w-full max-w-7xl px-8 my-4">
                <button
                disabled={currentPage === 1}
                onClick={handlePreviousPage}
                className="px-4 py-2 rounded-lg bg-blue-500 text-white"
                >
                Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
                className="px-4 py-2 rounded-lg bg-blue-500 text-white"
                >
                Next
                </button>
            </div>
        </div>
      </div>


      {selectedGrant && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Grant Details"
          className="bg-white p-6 rounded-lg shadow-lg max-w-3xl mx-auto"
          overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center"
        >
        {/* Title */}
        <h2 className="text-3xl font-bold text-center mb-6">{selectedGrant.name}</h2>

        {/* Content Sections */}
        <div className="flex">
          {/* Left Section */}
          <div className="w-1/2 pr-4">
            <p>
              <strong>Type:</strong> {selectedGrant.type || "N/A"}
            </p>
            <br />
            <p>
              <strong>Funding:</strong> {selectedGrant.funding || "N/A"}
            </p>
            <br />
            <p>
              <strong>Money goes to:</strong> {selectedGrant.money_to || "N/A"}
            </p>
          </div>

          {/* Divider */}
          <div className="w-px bg-gray-300 mx-4"></div>

          {/* Right Section */}
          <div className="w-1/2 pl-4">
            <p>
              <strong>Institution:</strong> {selectedGrant.institution || "N/A"}
            </p>
            <br />
            <p>
              <strong>Website:</strong>{" "}
              <a
                href={selectedGrant.website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                {selectedGrant.website || "N/A"}
              </a>
            </p>
            <br />
            <p>
              <strong>Person:</strong> {selectedGrant.person || "N/A"}
            </p>
            <br />
            <p>
              <strong>Description:</strong> {selectedGrant.description || "N/A"}
            </p>          
          </div>
        </div>

        {/* Close Button */}
        <button
          onClick={closeModal}
          className="mt-6 bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg block mx-auto"
        >
          Close
        </button>
      </Modal>
    )}

             
      
    </div>
  );
};

export default GrantsPage;
