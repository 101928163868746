import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-600 to-blue-500 flex flex-col items-center p-8">
      {/* Title */}
      <h1 className="text-4xl font-bold text-white mb-6">Privacy Policy</h1>

      {/* Content Box */}
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl">
        <p className="text-gray-800 mb-4">
          <strong>Effective Date:</strong> 1st of January 2025
        </p>
        <p className="text-gray-800 mb-4">
          At MakerTerminal, your privacy is our priority. This Privacy Policy explains how we collect, use, and protect your information when you use our site.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Information We Collect</h2>
        <ul className="list-disc list-inside text-gray-800 mb-4">
          <li>Personal Information: Details provided voluntarily through submission forms.</li>
          <li>Usage Data: Data such as pages visited on our site and interactions with our visualisations.</li>
          <li>Cookies and Tracking Technologies: Used for enhancing user experience and analytics.</li>
        </ul>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. How We Use Your Information</h2>
        <ul className="list-disc list-inside text-gray-800 mb-4">
          <li>To provide and improve the Service.</li>
          <li>To enhance dataset accuracy.</li>
          <li>To communicate updates if opted in.</li>
        </ul>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Data Sharing and Disclosure</h2>
        <p className="text-gray-800 mb-4">
          We may share data with service providers or comply with legal obligations. Aggregate data may be shared for research or educational purposes.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Your Rights</h2>
        <p className="text-gray-800 mb-4">
          You may access, update, or delete your personal information by contacting us.
        </p>
        <p className="text-gray-800 mb-4">
          For full details, contact us at info@makerterminal.com
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
