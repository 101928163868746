import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";


const AuthComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const navigate = useNavigate();

  // Auth state listener
  useEffect(() => {
    const getSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setSession(session);
    };

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
      }
    );

    // Clean up the listener
    return () => {
      authListener.subscription?.unsubscribe(); // This safely removes the listener if it exists
    };
  }, []);

  // Validate password
  const validatePassword = (password) => {
    const minLength = 6;
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (
      password.length >= minLength &&
      hasLetter &&
      hasNumber &&
      hasSpecialChar
    ) {
      return true;
    }
    return false;
  };

  const handleSignUp = async () => {
    setLoading(true);
    setError(null);
    setInfoMessage(null);

    if (!validatePassword(password)) {
      setError('Password must contain at least one letter, one number, one special character, and be at least 6 characters long.');
      setLoading(false);
      return;
    }

    try {
      const { user, error } = await supabase.auth.signUp({ email, password });
      if (error) throw error;
      console.log('User signed up:', user);
      navigate("/vcs");
      setInfoMessage('Please check your email inbox and verify your email before proceeding further.');
    } catch (error) {
      setError(error.message);
      console.error('Signup error:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async () => {
    setLoading(true);
    setError(null);
    try {
      const { user, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
      console.log("User signed in:", user);
      navigate("/vcs"); // Redirect to the protected page after successful login
    } catch (error) {
      setError(error.message);
      console.error("Sign-in error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    setLoading(true);
    setError(null);
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setSession(null);
      console.log("User signed out");
      navigate("/"); // Redirect to home after sign out
    } catch (error) {
      setError(error.message);
      console.error("Signout error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      });
      if (error) throw error;
    } catch (error) {
      setError(error.message);
      console.error('Google Sign-In Error:', error.message);
    }
  };
  

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white shadow-lg rounded-lg">
        {session ? (
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">
              Welcome, {session.user.email}
            </h2>
            <button
              onClick={handleSignOut}
              disabled={loading}
              className="w-full py-2 px-4 bg-red-500 hover:bg-red-600 text-white rounded-lg font-semibold"
            >
              {loading ? "Signing Out..." : "Sign Out"}
            </button>
          </div>
        ) : (
          <div>
            <h2 className="text-3xl font-bold text-center mb-6">
              Sign In / Sign Up
            </h2>
            <div className="mb-4">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-6">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={loading}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="space-y-4">
              
              <button
                onClick={handleSignUp}
                disabled={loading}
                className="w-full py-2 px-4 bg-green-500 hover:bg-green-600 text-white rounded-lg font-semibold"
              >
                {loading ? "Signing Up..." : "Sign Up"}
              </button>
              {error && <p className="text-red-500 text-center mt-4">{error}</p>}
              <button
                onClick={handleSignIn}
                disabled={loading}
                className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-lg font-semibold"
              >
                {loading ? "Signing In..." : "Sign In"}
              </button>
              
            </div>

            
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthComponent;
