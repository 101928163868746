import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import AuthComponent from './components/AuthComponent';
import ProtectedPage from './components/ProtectedPage';
import LandingPage from './components/LandingPage';
import './App.css';
import VCsFundsPage from './pages/VCsFundsPage';
import CompetitionsPage from './pages/CompetitionsPage';
import GrantsPage from './pages/GrantsPage';
import AcceleratorsPage from './pages/AcceleratorsPage';
import Header from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import Account from './components/Account';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ComingSoon from './pages/ComingSoon';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/vcs" element={
          <PrivateRoute>
            <VCsFundsPage />
          </PrivateRoute>
        } />
        <Route path="/competitions" element={
          <PrivateRoute>
            <CompetitionsPage />
          </PrivateRoute>
        } />
        <Route path="/grants" element={
          <PrivateRoute>
            <GrantsPage />
          </PrivateRoute>
        } />
        <Route path="/accelerators" element={
          <PrivateRoute>
            <AcceleratorsPage />
          </PrivateRoute>
        } />
        <Route path="/login" element={<AuthComponent />} />
        <Route path="/account" element={
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        } />
        <Route path="/protected" element={<ProtectedPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/comingsoon" element={<ComingSoon />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;